<template>
    <div class="statistiques">
        <h1>{{ $t('stats.title')}}</h1>
        <div class="select-filter">
            <div class="filter-item input-default">
                <select v-model="centerSelected" @change="filterStatsByCenter" v-if="centerList">
                    <option value="-1" disabled>{{ $t('stats.selectCenter')}}</option>
                    <option v-for="(item, idx) in centerList" :key="idx" :value="item.centers_id">{{ item.centers_name }}</option>
                </select>
            </div>
            <div class="filter-item input-default">
                <select v-model="doctorSelected" @change="filterStatsByDoctor" v-if="doctorList">
                    <option value="-1" disabled>{{ $t('stats.selectDoctor')}}</option>
                    <option v-for="(item, idx) in doctorList" :key="idx" :value="item.id">{{ `${item.firstname} ${item.lastname}` }}</option>
                </select>
            </div>
            <div class="filter-item">
                <div class="btn" @click="reset">{{$t('stats.reset')}}</div>
            </div>
        </div>
        <StatsMain :stats="stats"></StatsMain>
        <div class="pathology-container">
            <h2>{{ $t('stats.pathologyStatTitle')}}</h2>
            <StatPathology :max="pathologyCount" :pathology="item" v-for="(item, idx) in pathologies" :key="idx"></StatPathology>
        </div>
    </div>
</template>

<script>
import StatsMain from './../../components/stats/StatsMain'
import StatPathology from './../../components/stats/StatPathology'
import store from './../../store'
import axios from 'axios'

export default {
    name: 'Statistiques',
    components : {
        StatsMain,
        StatPathology
    },
    data() {
        return {
            centerSelected : -1,
            doctorSelected : -1,
            pathologyCount : 0,
            centerList : [],
            doctorList : [],
            stats: [],
            pathologies : []
        }
    },
    methods : {
        filterStatsByCenter() {
            this.doctorSelected = -1;
            if(this.centerSelected != -1) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let vm = this

                axios
                    .get(store.getters.getURL +"stats/center/" + this.centerSelected, config)
                    .then(function (response) {
                        vm.pathologies = response.data.pathology
                        vm.pathologyCount = response.data.pathology_max
                         vm.stats = response.data.main
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
                }
        },
        filterStatsByDoctor() {
            this.centerSelected = -1;
            if(this.doctorSelected != -1) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let vm = this

                axios
                    .get(store.getters.getURL +"stats/doctor/" + this.doctorSelected, config)
                    .then(function (response) {
                        vm.pathologies = response.data.pathology
                        vm.pathologyCount = response.data.pathology_max
                         vm.stats = response.data.main
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }
            
        },
        reset() {
            this.doctorSelected = -1;
            this.centerSelected = -1;
            this.getData();
        },
        getData() {
            let config = {
             validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .get(store.getters.getURL +"stats", config)
                .then(function (response) {
                    vm.pathologies = response.data.pathology
                    vm.pathologyCount = response.data.pathology_max
                    vm.stats = response.data.main
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    },
    mounted() {
        this.getData()

        //récupération des centers
        let config = {
            validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL + "centers", config)
            .then(function (response) {
                vm.centerList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });

        //récupération des praticiens
        axios
            .get(store.getters.getURL + "users", config)
            .then(function (response) {
                vm.doctorList = response.data.filter(e => e.role_id == 3)
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .statistiques {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:0 auto 80px auto;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
    }

    h1 {
        font-size:25px;
        font-family: $fontBlack;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top:20px;
    }

    .select-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:20px;
    }

    .filter-item {
        width:250px;
        max-width:90%;
        margin:10px;
    }

</style>